import type { Application, DataAkk } from '~/type/kmob/detail-akk'

export const useKmobAkkDetail = defineStore('akk-detail', {
  state: (): DataAkk => ({
    application: {} as Application,
    sections: [],
    step: '',
    activeTab: '',
    errors: false,
  }),
  getters: {
    isSections: (state) => {
      return state.sections.length > 0
    },
    isSectionHasData: (state) => {
      if (!state.application)
        return []
      if (state.application.survey_type === 'OPEN SURVEY') {
        return state.sections.filter(section => [1, 2, 3].includes(section.section_status))
      }
      else if (state.application.survey_type === 'SILENT SURVEY') {
        return state.sections
          .filter(section => [1, 2, 3].includes(section.section_status))
          .map((section) => {
            if (section.section === 'SI1')
              return { ...section, section: 'Sumber Informasi 1' }
            else if (section.section === 'SI2')
              return { ...section, section: 'Sumber Informasi 2' }
            else if (section.section === 'SI3')
              return { ...section, section: 'Sumber Informasi 3' }
            else if (section.section === 'FOTO')
              return { ...section, section: 'Foto & Dokumentasi' }
            else if (section.section === 'OBSERVE')
              return { ...section, section: 'Hasil Pengamatan' }
            else
              return section
          })
      }
    },
  },
  actions: {
    changeStatus() {
      this.application.order_status_name = 'SALLY-CANCEL'
    },
    async getAkkByProspectIdAction(prospect_id: string, survey_type: string) {
      const { data, error }: any = await useSallyFetchCms(`v1/kmob/akk/${prospect_id}/detail${survey_type === 'SILENT SURVEY' ? '?is_silent=true' : ''}`, { key: `${prospect_id}` })
      if (error?.value?.statusCode)
        return error?.value?.data

      if (data?.value) {
        this.application = data?.value?.data?.application
        this.sections = data?.value?.data?.sections
      }
    },
    async cancelAkkActions(prospect_id: string, cancel_reason_id: string, notes: string) {
      const loaderStore = useLoader()
      loaderStore.startLoading()
      try {
        const { data, error }: any = await useSallyFetch('/v1/kmob/submission/cancel', {
          server: false,
          body: {
            prospect_id,
            cancel_reason_id,
            notes,
          },
          method: 'POST',
        })
        if (error?.value?.statusCode) {
          loaderStore.stopLoading()
          return error?.value?.data
        }
        if (data?.value) {
          this.getAkkByProspectIdAction(prospect_id, this.application.survey_type)
          loaderStore.stopLoading()
          return data?.value
        }
      }
      catch (error: any) {
        return error?.value?.data
      }
    },
  },
  persist: {
    paths: ['application', 'step'],
    storage: persistedState.cookiesWithOptions({
      maxAge: 1 * 24 * 60 * 60,
    }),
  },
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useKmobAkkDetail, import.meta.hot))
