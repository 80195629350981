<script setup>
import { useCancelStatusBtn } from '~/composables/kmob/btn-render/useCancleBtn'
import { useKmobAkkDetail } from '~/stores/kmob/akk/detail'
import { useKmobTaksasi } from '~/stores/kmob/taksasi'

defineEmits(['change'])
const route = useRoute()
const router = useRouter()

const states = useKmobAkkDetail()
const { application } = storeToRefs(states)

const hasCancelAccess = !!useAuth().has('AKK KMOB', 'Cancel AKK')
const hasSubmitToLosAccess = !!useAuth().has('AKK KMOB', 'Submit to LOS')

const cancelModal = ref(false)
const cancelModalConfirm = ref(false)
const cancelModalFailedOrSuccess = ref(false)
const submitLosModal = ref(false)
const submitLosFailedModal = ref(false)
const submitLosSuccessModal = ref(false)

const is_empty_value_modal = ref(false)
const cancel_reason_id = ref(0)
const notes_cancel = ref('')
const cancel_reason_option = ref([])
async function getListCancelReason() {
  is_empty_value_modal.value = true
  try {
    const { data } = await useSallyFetch('/v1/kmob/cancel-reason', {
      params: {
        limit: 100,
      },
    })
    cancel_reason_option.value = data.value.data
  }
  catch (error) {
    console.error(error)
  }
}

function handleCancelApplication() {
  getListCancelReason()
  cancelModal.value = true
}

async function onCancelModal() {
  cancelModalConfirm.value = false
  cancelModalFailedOrSuccess.value = await states.cancelAkkActions(application.value.prospect_id, cancel_reason_id.value, notes_cancel.value)
}

async function onSubmitLos() {
  submitLosModal.value = false
  //   commmeted: wait api ready
  const isSucess = await useKmobTaksasi().submitToLos(application.value.prospect_id)

  if (isSucess)
    submitLosSuccessModal.value = true
  else
    submitLosFailedModal.value = true
}

async function handleRefetch() {
  submitLosSuccessModal.value = false
  await states.getAkkByProspectIdAction(route.params.id, route.query.survey_type ? route.query.survey_type : application?.value.survey_type)
}

const { cancelStatusBtn } = useCancelStatusBtn()

watch(
  () => cancel_reason_id.value,
  async (val) => {
    if (val !== 0 && val !== 18)
      is_empty_value_modal.value = false
    else
      is_empty_value_modal.value = true
  },
)

watch(
  () => notes_cancel.value,
  async (val) => {
    if (val !== '')
      is_empty_value_modal.value = false
    else
      is_empty_value_modal.value = true
  },
)
</script>

<template>
  <BaseCard class="p-6 mx-8 mb-[34px]">
    <div class="flex items-center justify-between gap-2">
      <div>
        <div
          v-if="cancelStatusBtn(hasCancelAccess, application?.order_status_name, 'AKK') && !route.fullPath.includes('section')"
          class="flex items-center cancel"
          @click="() => handleCancelApplication()"
        >
          Batalkan Pengajuan
        </div>
      </div>
      <div class="flex items-center gap-6 ">
        <KButton
          appearance="secondary"
          class="w-[176px]"
          @click="() => !route.fullPath.includes('section') ? navigateTo(`/kmob/akk`) : router.back()"
        >
          Kembali
        </KButton>
        <KButton
          v-if="hasSubmitToLosAccess && (application?.order_status_name === 'SURVEY-FINISHED')"
          class="w-[176px]"
          type="button"
          @click="() => submitLosModal = true"
        >
          Submit Ke LOS
        </KButton>
      </div>
    </div>
  </BaseCard>

  <BaseDialogBox
    :show-modal="cancelModalFailedOrSuccess"
    :appearance="cancelModalFailedOrSuccess.code !== 200 ? 'danger' : 'success'"
    :title="cancelModalFailedOrSuccess.code !== 200 ? `Error ${cancelModalFailedOrSuccess.code}` : 'Berhasil'"
    :message="cancelModalFailedOrSuccess.code !== 200 ? ` Silahkan Coba Beberapa Saat Lagi` : ' Proses Berhasil Dilakukan'"
    confirm-button-text="Tutup"
    @deny="cancelModalFailedOrSuccess = false"
    @confirm="cancelModalFailedOrSuccess = false"
  />
  <BaseDialogBox
    :show-modal="cancelModalConfirm"
    title="Batalkan Pengajuan?"
    message="Pengajuan yang dibatalkan tidak akan dilanjutkan ke proses berikutnya"
    appearance="warning"
    deny-button-text="Lanjutkan"
    confirm-button-text="Batalkan"
    deny-button-appearance="primary"
    confirm-button-appearance="secondary"
    @deny="onCancelModal"
    @confirm="cancelModalConfirm = false; notes_cancel = ''; cancel_reason_id = 0;"
  />
  <BaseModalForm
    :show-modal="cancelModal"
    appearance="warning"
    title="Konfirmasi Alasan Pembatalan Pengajuan"
    message="Silakan pilih alasan pembatalan untuk melanjutkan proses. "
    deny-button-text="Batalkan"
    confirm-button-text="Lanjutkan"
    deny-button-appearance="secondary"
    confirm-button-appearance="primary"
    :is-empty-value="is_empty_value_modal"
    @deny="cancelModal = false; notes_cancel = ''; cancel_reason_id = 0;"
    @confirm="cancelModal = false; cancelModalConfirm = true;"
  >
    <template #modalForm>
      <div class="flex flex-col gap-3">
        <KSelect
          v-model="cancel_reason_id"
          :items="cancel_reason_option.map(object => ({
            text: object.name,
            value: object.id,
          }))"
          label="Alasan Pembatalan"
          placeholder="Pilih Alasan Pembatalan"
          :clearable="false"
          searchable
          mode="single-radio"
          @change="() => $emit('change', cancel_reason_id)"
        />
        <KTextarea
          v-if="cancel_reason_id === 18"
          v-model="notes_cancel"
          label=""
          placeholder="Tulis alasan pembatalan"
          max-length="100"
          show-counter
        />
      </div>
    </template>
  </BaseModalForm>
  <BaseDialogBox
    :show-modal="submitLosModal"
    appearance="warning"
    title="Submit Pengajuan Ke LOS"
    message="Silakan klik “Submit” untuk melanjutkan proses pengajuan ke LOS"
    deny-button-text="Kembali"
    confirm-button-text="Submit"
    @deny="submitLosModal = false"
    @confirm="onSubmitLos"
  />
  <BaseDialogBox
    :show-modal="submitLosSuccessModal"
    appearance="success"
    title="Pengajuan Ini Sedang Diproses Di LOS"
    message="Hasil dari submit ke LOS dapat Anda lihat di halaman Detail Taksasi"
    confirm-button-text="Mengerti"
    @confirm="handleRefetch()"
  />
  <BaseDialogBox
    :show-modal="submitLosFailedModal"
    appearance="danger"
    title="Proses Submit Gagal"
    message="Telah terjadi kesalahan, silahkan coba lagi"
    deny-button-text="Kembali"
    confirm-button-text="Coba Lagi"
    @deny="submitLosFailedModal = false"
    @confirm="submitLosFailedModal = false;onSubmitLos()"
  />
</template>

<style scoped>
.cancel {
@apply cursor-pointer;
width: 156px;
height: 40px;
padding: 10px 12px 10px 12px;
gap: 4px;
border-radius: 4px 0px 0px 0px;
font-size: 14px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.0001em;
text-align: center;
color:#B22A09
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  background-color: #E8E8E8;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #E8E8E8;
}
</style>
